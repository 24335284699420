import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  ASKQUERIES_PATH,
  CHOROPLETH_PATH,
  CLIENT_PATH,
  CLIENTSUPPLIER_PATH,
  DASHBOARD_PATH,
  FILESUMMARY_PATH,
  GROUNDWATERLEVEL_PATH,
  MAINDASHBOARD_PATH,
  SELECTFILE_PATH,
  SUPPLIER_PATH,
  UPLOADFILE_PATH,
  USERREQUESTOPTION_PATH,
  VIEWData_PATH,
} from "../common/constant.js";
import { lazy, Suspense } from "react";
import Loader from "../component/Loader/index.js";
import ProtectedRoute from "./ProtectedRoutes";
import UserRequestOption from "../pages/UserRequestOption/index.jsx";
import AskQueries from "../pages/AskQueries/index.jsx";
import MainDashboard from "../pages/Dashboard2/index.jsx";


const Dashboard = lazy(() => import("../pages/dashboard/index.jsx"));
const Login = lazy(() => import("../pages/login"));
const NotFound = lazy(() => import("../pages/NotFound/index.js"));
const ViewExcelInfo = lazy(() => import("../pages/ViewData/index.js"));
const FileSummary = lazy(() => import("../pages/FileSummary/index.js"));
const UploadFile = lazy(() => import("../pages/UploadFile/index.js"));
const SelectFile = lazy(() => import("../pages/SelectFile/index.js"));
const GroundWaterLevel = lazy(() =>
  import("../pages/GroundWaterLevel/index.js")
);
const Suppliers = lazy(() => import("../pages/Suppliers/index.js"));
const Clients = lazy(() => import("../pages/Clients/index.js"));
const Layout = lazy(() => import("../container/Layout/Layout.js"));
const ClientsAndSuppliers = lazy(() =>
  import("../pages/ClientsAndSuppliers/index.js")
);
const ChoroplethScreen = lazy(() =>
  import("../../src/pages/Choropleth/index.jsx")
);

const AppRoute = () => {
  return (
    <Suspense fallback={<Loader isVisible={true} />}>
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route path="/" Component={Login} />
            <Route path="*" Component={NotFound} />

            {/* Wrap Protected Route  */}
            <Route element={<ProtectedRoute />}>
              <Route path={DASHBOARD_PATH} element={<Dashboard />} />
              <Route
                path={CLIENTSUPPLIER_PATH}
                element={<ClientsAndSuppliers />}
              />
              <Route path={CLIENT_PATH} element={<Clients />} />
              <Route path={SUPPLIER_PATH} element={<Suppliers />} />
              <Route
                path={GROUNDWATERLEVEL_PATH}
                element={<GroundWaterLevel />}
              />
              <Route path={UPLOADFILE_PATH} element={<UploadFile />} />
              <Route path={SELECTFILE_PATH} element={<SelectFile />} />
              <Route path={FILESUMMARY_PATH} element={<FileSummary />} />
              <Route path={VIEWData_PATH} element={<ViewExcelInfo />} />
              <Route
                path={USERREQUESTOPTION_PATH}
                element={<UserRequestOption />}
              />
              <Route path={ASKQUERIES_PATH} element={<AskQueries />} />
              <Route path={MAINDASHBOARD_PATH} element={<MainDashboard />} />
              <Route path={CHOROPLETH_PATH} element={<ChoroplethScreen />} />
            </Route>
          </Routes>
        </Layout>
      </BrowserRouter>
    </Suspense>
  );
};

export default AppRoute;
