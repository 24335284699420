import CardHeader from "../CardHeader";
import styles from "./styles.module.scss";
import { Chart } from "primereact/chart";

const ChartInCard = ({ data }) => {
  return (
    <div className={styles?.cardRoot}>
      <CardHeader props={data?.header} />
      <div className={styles?.chartContainer}>
        <Chart
          type="bar"
          data={{
            labels: ["Q1", "Q2", "Q3", "Q4"],
            datasets: [
              {
                label: "Sales",
                data: [540, 325, 702, 620],
                backgroundColor: [
                  "rgba(255, 159, 64, 0.2)",
                  "rgba(75, 192, 192, 0.2)",
                  "rgba(54, 162, 235, 0.2)",
                  "rgba(153, 102, 255, 0.2)",
                ],
                borderColor: [
                  "rgb(255, 159, 64)",
                  "rgb(75, 192, 192)",
                  "rgb(54, 162, 235)",
                  "rgb(153, 102, 255)",
                ],
                borderWidth: 1,
              },
            ],
          }}
          options={{
            scales: {
              y: {
                beginAtZero: true,
              },
            },
          }}
        />
      </div>
      {/* <div className={styles?.cardFooter}>
        <p>Bottom</p>
      </div> */}
    </div>
  );
};

export default ChartInCard;
