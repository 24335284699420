import { useNavigate } from "react-router-dom";
import { ASKQUERIES_PATH } from "../../common/constant";
import styles from "./styles.module.scss";

const UserRequestOption = () => {
  const navigate = useNavigate();
  const userRequestOption = [
    {
      btnLabel: "Report",
      description: "Report on Ground Water",
      path: "",
    },
    {
      btnLabel: "View Map",
      description: "View High Risk area in Map (Real Time)",
      path: "",
    },
    {
      btnLabel: "Chat",
      description: "Ask any queries",
      path: ASKQUERIES_PATH,
    },
  ];

  const CardContainer = ({ obj }) => {
    return (
      <div className={styles?.optionItem}>
        <div
          className={styles?.navigateBtn}
          onClick={() => {
            obj?.path && navigate(obj?.path);
          }}
        >
          <p>{obj?.btnLabel}</p>
        </div>
        <p className={styles?.description}>{obj?.description}</p>
      </div>
    );
  };

  return (
    <div className={styles?.root}>
      <div className={styles?.container}>
        <div>
          <p className={styles?.title}>Water - i</p>
        </div>
        <div className={styles?.optionRoot}>
          {userRequestOption?.map((optionObj, i) => (
            <CardContainer key={i} obj={optionObj} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default UserRequestOption;
