import styles from "./styles.module.scss";
import WaterDrop from "../../assets/images/images/waterDrop.png";
import IconInputField from "../../component/IconInputField/index.js";
import { InputIcon } from "primereact/inputicon";
import { useState } from "react";

const AskQueries = () => {
  const [searchValue, setSearchValue] = useState("");

  return (
    <div>
      <div className={styles?.container}>
        <div>
          <p className={styles?.title}>Water Nextent Labs</p>
        </div>
        <div className={styles?.iconContainer}>
          <img src={WaterDrop} alt="logoWithoutText" />
        </div>
        <div className={styles?.inputSearch}>
          <div>
            <IconInputField
              id="search"
              name="search"
              placeholder="Ask Nextent Labs !"
              value={searchValue}
              iconSrc={
                <InputIcon
                  className={"pi pi-send"}
                  style={{
                    color: "#4696DE",
                    fontSize: "24px",
                    cursor: "pointer",
                  }}
                  onClick={() => setSearchValue("")}
                ></InputIcon>
              }
              isPassword={false}
              onChange={(e) => setSearchValue(e?.target?.value)}
              iconVisible={searchValue ? true : false}
              style={{ height: "45px" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AskQueries;
