import { FormatDate } from "../../common/constant";
import CardHeader from "../CardHeader";
import PlainCustomTable from "../PrimeTable/PlainTable";
import styles from "./styles.module.scss";

const TableInCard = ({ data }) => {
  return (
    <div className={styles?.cardRoot}>
      <CardHeader props={data?.header} />
      <div className={styles?.listContainer}>
        <PlainCustomTable
          tableDetails={{
            header: { isVisible: false },
            scrollable: true,
            scrollHeight: "300px",
            paginator: {
              isVisible: true,
            },
          }}
          data={Array.from({ length: 20 }, (v, i) => ({
            villageName: "Palasamudram",
            siteName: "Dev Tech",
            id: i,
            filelocation: "filelocation " + i,
            createddate: FormatDate(new Date()),
          }))}
          columns={[
            {
              header: "Check Box",
              selectionMode: "multiple",
              template: "checkbox",
            },
            {
              header: "State",
              field: "filelocation",
            },
            {
              header: "District",
              field: "siteName",
            },
            {
              header: "Date",
              field: "createddate",
            },
          ]}
        />
      </div>
      {/* <div className={styles?.cardFooter}>
        <p>Bottom</p>
      </div> */}
    </div>
  );
};

export default TableInCard;
