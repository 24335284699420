import styles from "./styles.module.scss";

const PersonListItem = ({}) => {
  return (
    <div>
      <div className={styles?.listItemContainer}>
        <div className={styles?.userProfileContainer}>
          <p>L</p>
        </div>
        <div className={styles?.StationList}>
          <p className={styles?.name}>Station Name</p>
          <p className={styles?.desc}>Date</p>
        </div>
      </div>
    </div>
  );
};

export default PersonListItem;
