import CardHeader from "../CardHeader";
import styles from "./styles.module.scss";
import { Chart } from "primereact/chart";

const PieChartInCard = ({ data }) => {
  return (
    <div className={styles?.cardRoot}>
      <CardHeader props={data?.header} />
      {true && (
        <div className={""}>
          {true && (
            <Chart
              type="pie"
              data={{
                labels: ["A", "B", "C"],
                datasets: [
                  {
                    data: [540, 325, 702],
                    backgroundColor: ["red", "blue", "pink"],
                    hoverBackgroundColor: ["red", "blue", "pink"],
                  },
                ],
              }}
              options={{
                plugins: {
                  legend: {
                    labels: {
                      usePointStyle: true,
                    },
                  },
                },
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default PieChartInCard;
