import BreadCrumb from "../../component/Breadcrumb";
import ChartInCard from "../../component/ChartInCard";
import ListInCard from "../../component/ListInCard";
import PieChartInCard from "../../component/PieChartInCard";
import TableInCard from "../../component/TableInCard";
import styles from "./styles.module.scss";
import { useState } from "react";

const MainDashboard = () => {
  const primary = "#4696DE";
  const [searchValue, setSearchValue] = useState("");
  const cardDetailsObj = {
    workItems: {
      header: {
        header: { title: "Recent Data Cleaning Uploads" },
        iconDetails: {
          refresh: {
            isVisible: false,
          },
          document: {
            isVisible: false,
          },
        },
      },
    },
    openLeads: {
      header: {
        header: { title: "Wells / Bores - Status" },
        iconDetails: {
          showAll: {
            isVisible: false,
          },
          refresh: {
            style: { color: primary },
          },
          document: {
            style: { color: primary },
          },
          zoom: {
            style: { color: primary },
          },
        },
      },
    },
    recentCalls: {
      header: {
        header: { title: "Recent Calibrated wells/bores​" },
        iconDetails: {
          refresh: {
            isVisible: false,
          },
          document: {
            isVisible: false,
          },
        },
      },
    },
    pipeline: {
      header: {
        header: { title: "State wise – Monitoring Status​" },
        iconDetails: {
          refresh: {
            isVisible: false,
          },
          document: {
            isVisible: false,
          },
        },
      },
    },
    opportunities: {
      header: {
        header: { title: "Upcoming Calibrations​" },
        iconDetails: {
          refresh: {
            isVisible: false,
          },
          document: {
            isVisible: false,
          },
        },
      },
    },
  };
  const cardDetails = [
    {
      header: {
        header: { title: "Recent calls" },
      },
    },
    {
      header: {
        header: { title: "Pipeline by stage" },
      },
    },
    {
      header: {
        header: { title: "Opportunities at risk" },
      },
    },
  ];

  return (
    <div>
      <div className={styles?.root}>
        <p className="pageTitle pb-2">GWL Dashboard</p>
        <BreadCrumb />
        <div className={styles?.container}>
          <div className={styles?.subContainer}>
            <ListInCard data={cardDetailsObj?.workItems} />
            <PieChartInCard data={cardDetailsObj?.openLeads} />
          </div>
          <TableInCard data={cardDetailsObj?.recentCalls} />
          <ChartInCard data={cardDetailsObj?.pipeline} />
          <TableInCard data={cardDetailsObj?.opportunities} />
        </div>
      </div>
    </div>
  );
};

export default MainDashboard;
