import PButton from "../button";
import styles from "./styles.module.scss";

const BreadCrumb = ({}) => {
  return (
    <div className={styles?.breadCrumb}>
      <div>
        <PButton
          label={"Refresh All"}
          icon="pi pi-refresh"
          className={`${styles?.customBtn}`}
          color={"invertBtn"}
        />
      </div>

      <div>
        <PButton
          label={"Share"}
          icon="fa-regular fa-share-from-square"
          className={styles?.customBtn}
        />
      </div>
    </div>
  );
};

export default BreadCrumb;
