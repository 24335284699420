import styles from "./styles.module.scss";

const Label = ({ children, className, ...rest }) => {
  return (
    <label className={`${styles?.label}`} {...rest}>
      <p className={`${className}`}>{children}</p>
    </label>
  );
};

export default Label;
