import { ApiEndPoints } from "./apiEndPoints";
import BaseService from "./base";

export const UploadExcelService = async (payload) => {
  try {
    const res = await BaseService.post(ApiEndPoints?.uploadExcel, payload);
    return res?.data;
  } catch (error) {
    return new Error(error?.message);
  }
};

export const InsertDataService = async (payload) => {
  try {
    const res = await BaseService.post(ApiEndPoints?.insertData, payload);
    return res?.data;
  } catch (error) {
    return new Error(error?.message);
  }
};

export const GetFileService = async () => {
  try {
    const res = await BaseService.get(ApiEndPoints?.getFile);
    return res?.data;
  } catch (error) {
    return new Error(error?.message);
  }
};

export const GetWaterDataService = async (payload) => {
  try {
    const res = await BaseService.post(ApiEndPoints?.getWaterData, payload);
    return res?.data;
  } catch (error) {
    return new Error(error?.message);
  }
};

export const DeleteFileService = async (payload) => {
  try {
    const res = await BaseService.delete(ApiEndPoints?.deleteFile, payload);
    return res?.data;
  } catch (error) {
    return new Error(error?.message);
  }
};

export const getPlotDataService = async (payload) => {
  try {
    const res = await BaseService.post(ApiEndPoints?.getPlotData, payload);
    return res?.data;
  } catch (error) {
    return new Error(error?.message);
  }
};

export const getMonthlyDataService = async (payload) => {
  try {
    const res = await BaseService.post(ApiEndPoints?.getMonthlyData, payload);
    return res?.data;
  } catch (error) {
    return new Error(error?.message);
  }
};

// export const getPlotMonthlyYearsService = async () => {
//   try {
//     const res = await BaseService.get(ApiEndPoints?.getPlotMonthlyYears);
//     return res?.data;
//   } catch (error) {
//     return new Error(error?.message);
//   }
// };

export const getPlotMonthlyYearsService = async (payload) => {
  try {
    const res = await BaseService.post(
      ApiEndPoints?.getPlotMonthlyYears,
      payload
    );
    return res?.data;
  } catch (error) {
    return new Error(error?.message);
  }
};

export const getHeatmapDataService = async (payload) => {
  try {
    const res = await BaseService.post(ApiEndPoints?.getHeatmapData, payload);
    return res?.data;
  } catch (error) {
    return new Error(error?.message);
  }
};

export const getForecastService = async (payload) => {
  try {
    const res = await BaseService.post(ApiEndPoints?.getForecastData, payload);
    return res?.data;
  } catch (error) {
    return new Error(error?.message);
  }
};

export const GetPlotMapService = async () => {
  try {
    const res = await BaseService.get(ApiEndPoints?.getPlotMapsData);
    return res?.data;
  } catch (error) {
    return new Error(error?.message);
  }
};
