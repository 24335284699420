import CardHeader from "../CardHeader";
import PersonListItem from "../PersonListItem";
import styles from "./styles.module.scss";

const ListInCard = ({ data }) => {
  return (
    <div className={styles?.cardRoot}>
      <CardHeader props={data?.header} />
      <div className={styles?.listContainer}>
        {Array.from({ length: 10 })?.map((obj, i) => (
          <PersonListItem data={{}} />
        ))}
      </div>
      <div className={styles?.cardFooter}>
        <p>10 items</p>
        <p>Last updated at 6.30 pm</p>
      </div>
    </div>
  );
};

export default ListInCard;
