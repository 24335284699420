export const BACKEND_BASE_URL = "https://nextentlabs.com:81";

export const ApiEndPoints = {
  login: "/login",
  logout: "/logout",
  uploadExcel: "/fileupload",
  insertData: "/insertdata",
  getFile: "/getFiles",
  getWaterData: "/getwaterData",
  deleteFile: "/dummyDelete",
  getPlotData: "/getPlotData",
  getMonthlyData: "/getmonthlyData",
  getPlotMonthlyYears: "/plot_monthly_years",
  getHeatmapData: "/getheatmapData",
  getForecastData: "/forecast",
  getPlotMapsData: "/plot_maps_data",
};
