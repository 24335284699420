import styles from "./styles.module.scss";

const CardHeader = ({ props }) => {
  const data = {
    header: {
      ...props?.header,
    },
    iconDetails: {
      zoom: {
        isVisible: true,
        styles: {},
        ...props?.iconDetails?.zoom,
      },
      refresh: {
        isVisible: true,
        styles: {},
        ...props?.iconDetails?.refresh,
      },
      document: {
        isVisible: true,
        styles: {},
        ...props?.iconDetails?.document,
      },
      menu: {
        isVisible: true,
        styles: {},
        ...props?.iconDetails?.menu,
      },
      showAll: {
        isVisible: true,
        styles: {},
        ...props?.iconDetails?.showAll,
      },
    },
  };
  return (
    <div className={styles?.headerRoot}>
      <p className={styles?.headerTitle}>{data?.header?.title}</p>
      <div className={styles?.iconContainer}>
        {data?.iconDetails?.document?.isVisible && (
          <i
            className="fa-regular fa-file-lines"
            style={data?.iconDetails?.document?.style}
          ></i>
        )}
        {data?.iconDetails?.refresh?.isVisible && (
          <i
            className="pi pi-refresh"
            style={data?.iconDetails?.refresh?.style}
          ></i>
        )}
        {data?.iconDetails?.zoom?.isVisible && (
          <i
            className="fa-solid fa-magnifying-glass-plus"
            style={data?.iconDetails?.zoom?.style}
          ></i>
        )}
        {data?.iconDetails?.showAll?.isVisible && (
          <p className={styles?.helperText}>Show All</p>
        )}
      </div>
    </div>
  );
};

export default CardHeader;
